:root {
  --color-black: #000;
  --color-green-dark: #408939;
  --color-green-extralight: #e0f2df;
  --color-green-light: #b4deb0;
  --color-green: #71c169;
  --color-grey-30: #ededed;
  --color-grey-40: #c9c9c9;
  --color-grey-50: #a1a1a1;
  --color-grey-60: #777;
  --color-grey-background: #f6f6f6;
  --color-orange: #ef7908;
  --color-red-light: #fff5f6;
  --color-red-dark: #ad0018;
  --color-red: #f90023;
  --color-text-light: #777;
  --color-white: #fff;
  --font-family-header: 'Montserrat', sans-serif;
  --font-family-body: 'Open Sans', sans-serif;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --line-height-s: 1.3;
  --line-height-m: 1.6;
  --font-size-s: 13px;
  --font-size-m: 15px;
  --font-size-l: 18px;
  --font-size-xl: 24px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 36px;
  --container-s: 534px;
  --container-m: 634px;
  --container-l: 1248px;
  --border-radius: 4px;
  --box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  --transition: 200ms ease-out;
  --z-index-header: 900;
  --z-index-modal: 1000;
  --z-index-premium-button-overlay: 900;
}

::selection {
  background: var(--color-red);
  color: var(--color-white);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-grey-background);
  font-family: var(--font-family-body);
  margin: 0;
  overflow-y: scroll;
  padding: 0;
}

input,
textarea,
select,
button {
  font-family: var(--font-family-body);
}

h1,
h2,
h3 {
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-semibold);
}
